window.onload = function () {
    function tog(v) {
        return v ? 'addClass' : 'removeClass';
    }

    $.expr[':'].icontains = function (obj, index, meta, stack) {
        return (obj.textContent || obj.innerText || jQuery(obj).text() || '').toLowerCase().indexOf(meta[3].toLowerCase()) >= 0;
    };

    $(document)
        .on('input', '#search', function () {
            that = $('#recipe-list')

            $(this)[tog(this.value)]('filtered');
            $(that)[tog(this.value)]('filtered');

            headings = that.find('h1,h2')
            search_items = that.find('li.recipe-link')

            if (this.value) {
                search_items.hide();
                headings.hide();
                $('#recent').hide();
                search_items.filter(`:icontains('${this.value}')`).show();
            }
            else {
                search_items.show();
                headings.show();
                $('#recent').show();
            }

        })
        .on('mousemove', '.filtered', function (e) {
            $(this)[tog(this.offsetWidth - 36 < e.clientX - this.getBoundingClientRect().left)]('onX');
        })
        .on('touchstart click', '.onX.filtered', function (ev) {
            ev.preventDefault();
            $(this).removeClass('filtered onX').val('').trigger("input");
        });

    $('#search').trigger('input');
};
